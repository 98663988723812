import React, { memo } from 'react';
import { Image } from 'react-native';
import { asBaseComponent } from "../../commons/new";
const Icon = memo(props => {
  const {
    size,
    tintColor,
    style,
    modifiers,
    ...others
  } = props;
  const {
    margins
  } = modifiers;
  const iconSize = size ? {
    width: size,
    height: size
  } : undefined;
  return <Image {...others} style={[style, margins, iconSize, {
    tintColor
  }]} />;
});
Icon.displayName = 'Icon';
export default asBaseComponent(Icon);