import _pt from "prop-types";
import React, { useRef, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Spacings, Colors, BorderRadiuses } from "../../style";
import { Constants } from "../../commons/new";
import { useDidUpdate } from "../../hooks";
import View from "../../components/view";
import ImperativeDialog from "./ImperativeDialog";
import DialogHeader from "./DialogHeader";
import DialogText from "./DialogText";
import DialogKnob from "./DialogKnob";
import DialogDivider from "./DialogDivider";
import { DialogDirections, DialogDirectionsEnum, DialogHeaderProps, DialogTextProps } from "./types";
export { DialogDirections, DialogDirectionsEnum, DialogHeaderProps, DialogTextProps };

const Dialog = props => {
  const {
    visible,
    headerProps,
    containerStyle,
    children,
    ...others
  } = props;
  const initialVisibility = useRef(visible);
  const dialogRef = React.createRef();
  useDidUpdate(() => {
    if (visible) {
      dialogRef.current?.open();
    } else {
      dialogRef.current?.close();
    }
  }, [visible]);
  const style = useMemo(() => {
    return [styles.defaultDialogStyle, containerStyle];
  }, [containerStyle]);
  return <ImperativeDialog {...others} initialVisibility={initialVisibility.current} ref={dialogRef}>
      <View style={style}>
        <DialogHeader {...headerProps} />
        {children}
      </View>
    </ImperativeDialog>;
};

Dialog.propTypes = {
  /**
     * The visibility of the dialog.
     */
  visible: _pt.bool
};
Dialog.displayName = 'Incubator.Dialog';
Dialog.directions = DialogDirectionsEnum;
Dialog.Header = DialogHeader;
Dialog.Text = DialogText;
Dialog.Knob = DialogKnob;
Dialog.Divider = DialogDivider;
export default Dialog;
const styles = StyleSheet.create({
  defaultDialogStyle: {
    marginBottom: Spacings.s5,
    backgroundColor: Colors.white,
    maxHeight: Constants.screenHeight * 0.6,
    width: 250,
    borderRadius: BorderRadiuses.br20,
    overflow: 'hidden'
  }
});