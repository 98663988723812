import { useContext, useImperativeHandle, useRef } from 'react';
import FieldContext from "./FieldContext";

const useImperativeInputHandle = (ref, props) => {
  const inputRef = useRef();
  const context = useContext(FieldContext);
  useImperativeHandle(ref, () => {
    return {
      focus: () => inputRef.current?.focus(),
      blur: () => inputRef.current?.blur(),
      clear: () => {
        inputRef.current?.clear(); // NOTE: This fixes an RN issue - when triggering imperative clear method, it doesn't call onChangeText

        props.onChangeText?.('');
      },
      validate: () => {
        context.validateField();
      }
    };
  });
  return inputRef;
};

export default useImperativeInputHandle;