import { colorsPalette } from "./colorsPalette";
export const designTokens = {
  // BACKGROUND
  backgroundDefault: colorsPalette.white,
  backgroundNeutralHeavy: colorsPalette.grey20,
  backgroundIdle: colorsPalette.grey40,
  backgroundNeutralMedium: colorsPalette.grey60,
  backgroundNeutral: colorsPalette.grey70,
  backgroundNeutralLight: colorsPalette.grey80,
  backgroundPrimaryHeavy: colorsPalette.violet30,
  backgroundPrimaryLight: colorsPalette.violet70,
  backgroundPrimary: colorsPalette.violet80,
  backgroundGeneralHeavy: colorsPalette.blue30,
  backgroundGeneralLight: colorsPalette.blue70,
  backgroundGeneral: colorsPalette.blue80,
  backgroundSuccessHeavy: colorsPalette.green30,
  backgroundSuccess: colorsPalette.green80,
  backgroundWarningHeavy: colorsPalette.yellow30,
  backgroundWarning: colorsPalette.yellow80,
  backgroundMajor: colorsPalette.orange80,
  backgroundDangerHeavy: colorsPalette.red30,
  backgroundDanger: colorsPalette.red80,
  backgroundDisabled: colorsPalette.grey50,
  backgroundDark: colorsPalette.grey10,
  // TEXT
  textDisabled: colorsPalette.grey50,
  textDefault: colorsPalette.grey10,
  textNeutralHeavy: colorsPalette.grey20,
  textNeutral: colorsPalette.grey30,
  textNeutralLight: colorsPalette.grey40,
  textWhite: colorsPalette.white,
  textWhiteDisabled: colorsPalette.white,
  textPrimary: colorsPalette.violet30,
  textGeneral: colorsPalette.blue30,
  textSuccess: colorsPalette.green10,
  textMajor: colorsPalette.orange10,
  textDanger: colorsPalette.red10,
  textDangerLight: colorsPalette.red30,
  // ICON
  iconDefault: colorsPalette.grey10,
  iconNeutral: colorsPalette.grey20,
  iconWhite: colorsPalette.white,
  iconWhiteDisabled: colorsPalette.white,
  iconPrimary: colorsPalette.violet30,
  iconPrimaryLight: colorsPalette.violet50,
  iconGeneral: colorsPalette.blue30,
  iconGeneralLight: colorsPalette.blue50,
  iconSuccess: colorsPalette.green10,
  iconSuccessLight: colorsPalette.green40,
  iconMajor: colorsPalette.orange10,
  iconDanger: colorsPalette.red10,
  iconDangerLight: colorsPalette.red40,
  iconDisabled: colorsPalette.grey50,
  // OUTLINE
  outlineWhite: colorsPalette.white,
  outlineNeutral: colorsPalette.grey60,
  outlineDisabled: colorsPalette.grey50,
  outlinePrimary: colorsPalette.violet30,
  outlineGeneral: colorsPalette.blue30,
  outlineWarning: colorsPalette.yellow30,
  outlineDanger: colorsPalette.red30,
  // DIVIDER
  // dividerDisabled: TBD
  dividerNeutral: colorsPalette.grey50,
  divider: colorsPalette.grey60
};