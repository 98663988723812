import React, { useCallback, useState } from 'react';
import { Constants } from "../../commons/new";
export default function useHiddenLocation(props) {
  const {
    containerRef
  } = props;

  const getHiddenLocation = ({
    x = 0,
    y = 0,
    width = Constants.screenWidth,
    height = Constants.windowHeight,
    isDefault = true
  }) => {
    return {
      up: -y - height,
      down: Constants.windowHeight - y,
      left: -width - x,
      right: Constants.screenWidth - x,
      isDefault
    };
  };

  const [hiddenLocation, setHiddenLocation] = useState(getHiddenLocation({}));
  const onLayout = useCallback(event => {
    const {
      width,
      height
    } = event.nativeEvent.layout;

    if (containerRef.current) {
      containerRef.current.measureInWindow((x, y) => {
        setHiddenLocation(getHiddenLocation({
          x,
          y,
          width,
          height,
          isDefault: false
        }));
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  return {
    onLayout,
    hiddenLocation
  };
}