import _pt from "prop-types";
import React, { PureComponent } from 'react';
import { Text as RNText, StyleSheet, Animated } from 'react-native';
import _ from 'lodash';
import { asBaseComponent, forwardRef } from "../../commons/new";
import { Colors } from "../../style";
import { TextUtils } from "../../utils";

/**
 * @description: A wrapper for Text component with extra functionality like modifiers support
 * @extends: Text
 * @extendsLink: https://reactnative.dev/docs/text
 * @modifiers: margins, color, typography
 * @example: https://github.com/wix/react-native-ui-lib/blob/master/demo/src/screens/componentScreens/TextScreen.js
 * @image: https://github.com/wix/react-native-ui-lib/blob/master/demo/showcase/Text/Modifiers.png?raw=true, https://github.com/wix/react-native-ui-lib/blob/master/demo/showcase/Text/Transformation.png?raw=true, https://github.com/wix/react-native-ui-lib/blob/master/demo/showcase/Text/Highlights.png?raw=true
 */
class Text extends PureComponent {
  static propTypes = {
    /**
         * color of the text
         */
    color: _pt.string,

    /**
         * Whether to center the text (using textAlign)
         */
    center: _pt.bool,

    /**
         * Whether to change the text to uppercase
         */
    uppercase: _pt.bool,

    /**
         * Whether to add an underline
         */
    underline: _pt.bool,

    /**
         * Substring to highlight
         */
    highlightString: _pt.oneOfType([_pt.string, _pt.arrayOf(_pt.string)]),

    /**
         * Use Animated.Text as a container
         */
    animated: _pt.bool,
    textAlign: _pt.string
  };
  static displayName = 'Text';
  TextContainer = this.props.animated ? Animated.createAnimatedComponent(RNText) : RNText; // setNativeProps(nativeProps) {
  //   this._root.setNativeProps(nativeProps); // eslint-disable-line
  // }

  renderText(children) {
    const {
      highlightString,
      highlightStyle
    } = this.props;

    if (!_.isEmpty(highlightString)) {
      if (_.isArray(children)) {
        return _.map(children, child => {
          return this.renderText(child);
        });
      }

      if (_.isString(children)) {
        const textParts = highlightString && TextUtils.getPartsByHighlight(children, highlightString);
        return textParts && _.map(textParts, (text, index) => {
          return <RNText key={index} style={text.shouldHighlight ? [styles.highlight, highlightStyle] : styles.notHighlight}>
                {text.string}
              </RNText>;
        });
      }
    }

    return children;
  }

  render() {
    const {
      modifiers,
      style,
      center,
      uppercase,
      underline,
      children,
      forwardedRef,
      ...others
    } = this.props;
    const color = this.props.color || modifiers.color;
    const {
      margins,
      typography,
      backgroundColor,
      flexStyle
    } = modifiers;
    const textStyle = [styles.container, typography, color && {
      color
    }, backgroundColor && {
      backgroundColor
    }, flexStyle, margins, center && styles.centered, uppercase && styles.uppercase, underline && styles.underline, style];
    const TextContainer = this.TextContainer;
    return <TextContainer {...others} style={textStyle} ref={forwardedRef}>
        {this.renderText(children)}
      </TextContainer>;
  }

}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    textAlign: 'left'
  },
  centered: {
    textAlign: 'center'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  underline: {
    textDecorationLine: 'underline'
  },
  highlight: {
    color: Colors.grey30
  },
  notHighlight: {
    color: undefined
  }
});
export { Text }; // For tests

export default asBaseComponent(forwardRef(Text));