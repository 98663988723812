import _pt from "prop-types";
import React, { useCallback, useMemo } from 'react';
import Reanimated, { useAnimatedStyle } from 'react-native-reanimated';
import { Colors, Spacings, Typography } from "../../style";
import { asBaseComponent } from "../../commons/new";
import TouchableOpacity from "../touchableOpacity";

/**
 * Segment sub-component for SegmentedControl component
 */
const Segment = React.memo(props => {
  const {
    activeColor = Colors.primary,
    label,
    iconSource,
    iconStyle,
    selectedIndex,
    onLayout,
    onPress,
    inactiveColor,
    index,
    iconOnRight,
    testID
  } = props;
  const animatedTextStyle = useAnimatedStyle(() => {
    const color = selectedIndex?.value === index ? activeColor : inactiveColor;
    return {
      color
    };
  });
  const animatedIconStyle = useAnimatedStyle(() => {
    const tintColor = selectedIndex?.value === index ? activeColor : inactiveColor;
    return {
      tintColor
    };
  });
  const segmentStyle = useMemo(() => ({
    paddingHorizontal: Spacings.s3,
    paddingVertical: Spacings.s2
  }), []);
  const renderIcon = useCallback(() => {
    return iconSource && <Reanimated.Image source={iconSource} style={[animatedIconStyle, iconStyle]} />; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconSource, iconStyle]);
  const onSegmentPress = useCallback(() => {
    selectedIndex?.value !== index && onPress?.(index); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, onPress]);
  const segmentOnLayout = useCallback(event => {
    onLayout?.(index, event);
  }, [onLayout, index]);
  return <TouchableOpacity onLayout={segmentOnLayout} style={segmentStyle} onPress={onSegmentPress} row flexG center testID={`${testID}.${index}`}>
      {!iconOnRight && renderIcon()}
      {label && <Reanimated.Text numberOfLines={1} style={[animatedTextStyle, Typography.text90]}>
          {label}
        </Reanimated.Text>}
      {iconOnRight && renderIcon()}
    </TouchableOpacity>;
});
Segment.propTypes = {
  /**
     * The label of the segment.
     */
  label: _pt.string,

  /**
     * Should the icon be on right of the label
     */
  iconOnRight: _pt.bool,

  /**
     * The color of the active segment (label and outline).
     */
  activeColor: _pt.string,

  /**
     * The color of the inactive segment (label).
     */
  inactiveColor: _pt.string,

  /**
     * Callback for when segment has pressed.
     */
  onPress: _pt.func,

  /**
     * The index of the segment.
     */
  index: _pt.number.isRequired,

  /**
     * onLayout function.
     */
  onLayout: _pt.func,
  testID: _pt.string
};
export default asBaseComponent(Segment);