import React, { useCallback } from 'react';
import { runOnJS } from 'react-native-reanimated';
export default function useAnimationEndNotifier(props) {
  const {
    onAnimationEnd
  } = props;
  const onEnterAnimationEnd = useCallback(isFinished => {
    'worklet';

    if (onAnimationEnd && isFinished) {
      runOnJS(onAnimationEnd)('enter');
    }
  }, [onAnimationEnd]);
  const onExitAnimationEnd = useCallback(isFinished => {
    'worklet';

    if (onAnimationEnd && isFinished) {
      runOnJS(onAnimationEnd)('exit');
    }
  }, [onAnimationEnd]);
  return {
    onEnterAnimationEnd,
    onExitAnimationEnd
  };
}