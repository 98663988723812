import { isEmpty } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { extractAlignmentsValues } from "../../../commons/modifiers";
export let AlignmentType;

(function (AlignmentType) {
  AlignmentType["DEFAULT"] = "default";
  AlignmentType["BOTTOM"] = "bottom";
  AlignmentType["TOP"] = "top";
})(AlignmentType || (AlignmentType = {}));

const useAlignmentStyle = props => {
  const alignmentType = useRef(AlignmentType.DEFAULT);

  const getAlignments = () => {
    const alignments = extractAlignmentsValues(props);

    if (isEmpty(alignments)) {
      return styles.defaultAlignment;
    } else {
      alignmentType.current = props?.bottom ? AlignmentType.BOTTOM : AlignmentType.TOP;
      return alignments;
    }
  };

  const alignments = getAlignments();
  const alignmentStyle = useMemo(() => {
    return [styles.container, alignments];
  }, [alignments]);
  return {
    alignmentType: alignmentType.current,
    alignmentStyle
  };
};

export default useAlignmentStyle;
const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  defaultAlignment: {
    justifyContent: 'center',
    alignItems: 'center'
  }
});