/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSharedValue, withTiming, useAnimatedStyle } from 'react-native-reanimated';
import { Colors } from "../../../style";
import View from "../../../components/view";
const DEFAULT_OVERLAY_BACKGROUND_COLORS = Colors.rgba(Colors.black, 0.2);

const useFadeView = props => {
  const {
    initialVisibility,
    overlayBackgroundColor = DEFAULT_OVERLAY_BACKGROUND_COLORS,
    testID
  } = props;
  const fadeOpacity = useSharedValue(Number(initialVisibility));
  const hideNow = useCallback(() => {
    fadeOpacity.value = 0;
  }, []);
  const fade = useCallback(type => {
    fadeOpacity.value = withTiming(Number(type === 'enter'), {
      duration: 300
    });
  }, []);
  const fadeStyle = useAnimatedStyle(() => {
    return {
      opacity: fadeOpacity.value,
      backgroundColor: overlayBackgroundColor
    };
  }, [overlayBackgroundColor]);
  const FadeView = <View testID={testID} absF reanimated style={fadeStyle} pointerEvents="none" />;
  return {
    FadeView,
    hideNow,
    fade
  };
};

export default useFadeView;