/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { runOnJS } from 'react-native-reanimated';
import useAnimatedTranslator, { TransitionViewDirectionEnum } from "./useAnimatedTranslator";
import useAnimationEndNotifier from "./useAnimationEndNotifier";
export default function useAnimatedTransition(props) {
  const {
    hiddenLocation,
    enterFrom,
    exitTo,
    onAnimationStart,
    onAnimationEnd
  } = props;
  const {
    init,
    animate,
    animatedStyle
  } = useAnimatedTranslator({
    initialVisibility: !enterFrom
  });
  const {
    onEnterAnimationEnd,
    onExitAnimationEnd
  } = useAnimationEndNotifier({
    onAnimationEnd
  });

  const getLocation = direction => {
    return {
      x: //@ts-expect-error
      direction && [TransitionViewDirectionEnum.LEFT, TransitionViewDirectionEnum.RIGHT].includes(direction) ? hiddenLocation[direction] : 0,
      y: //@ts-expect-error
      direction && [TransitionViewDirectionEnum.UP, TransitionViewDirectionEnum.DOWN].includes(direction) ? hiddenLocation[direction] : 0
    };
  };

  useEffect(() => {
    if (!hiddenLocation.isDefault && enterFrom) {
      const location = getLocation(enterFrom);
      init(location, enterFrom, enter);
    }
  }, [hiddenLocation.isDefault]);
  const enter = useCallback(() => {
    'worklet';

    if (enterFrom) {
      if (onAnimationStart) {
        runOnJS(onAnimationStart)('enter');
      }

      animate({
        x: 0,
        y: 0
      }, enterFrom, onEnterAnimationEnd);
    }
  }, [onEnterAnimationEnd]);
  const exit = useCallback(() => {
    'worklet';

    if (exitTo) {
      if (onAnimationStart) {
        runOnJS(onAnimationStart)('exit');
      }

      animate(getLocation(exitTo), exitTo, onExitAnimationEnd);
    }
  }, [hiddenLocation, exitTo, onExitAnimationEnd]);
  return {
    exit,
    animatedStyle
  };
}