import React, { useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import TouchableOpacity from "../../components/touchableOpacity";
import View from "../../components/view";
import Modal from "../../components/modal";
import Dialog from "../../components/dialog";

const ExpandableOverlay = (props, ref) => {
  const {
    children,
    expandableContent,
    useDialog,
    modalProps,
    dialogProps,
    showTopBar,
    topBarProps,
    renderCustomOverlay,
    disabled,
    testID,
    ...others
  } = props;
  const [visible, setExpandableVisible] = useState(false);
  const openExpandable = useCallback(() => setExpandableVisible(true), []);
  const closeExpandable = useCallback(() => {
    setExpandableVisible(false);
    useDialog ? dialogProps?.onDismiss?.() : modalProps?.onDismiss?.();
  }, [useDialog, dialogProps?.onDismiss, modalProps?.onDismiss]);
  const toggleExpandable = useCallback(() => visible ? closeExpandable() : openExpandable(), [visible, openExpandable, closeExpandable]);
  useImperativeHandle(ref, () => ({
    openExpandable,
    closeExpandable,
    toggleExpandable
  }));

  const renderModal = () => {
    return <Modal testID={`${testID}.overlay`} {...modalProps} visible={visible} onDismiss={closeExpandable}>
        {showTopBar && <Modal.TopBar onDone={closeExpandable} {...topBarProps} />}
        {expandableContent}
      </Modal>;
  };

  const renderDialog = () => {
    return <Dialog testID={`${testID}.overlay`} {...dialogProps} visible={visible} onDismiss={closeExpandable}>
        {expandableContent}
      </Dialog>;
  };

  const renderOverlay = () => {
    if (renderCustomOverlay) {
      return renderCustomOverlay({
        visible,
        openExpandable,
        closeExpandable,
        toggleExpandable
      });
    } else {
      return useDialog ? renderDialog() : renderModal();
    }
  };

  return <TouchableOpacity {...others} onPress={openExpandable} disabled={disabled} testID={testID}>
      <View pointerEvents="none">{children}</View>
      {renderOverlay()}
    </TouchableOpacity>;
};

export default forwardRef(ExpandableOverlay);