import React from 'react';
import { Dividers } from "../../style";
import View from "../../components/view";

const DialogDivider = props => {
  const {
    showDivider = true
  } = props;

  if (showDivider) {
    return <View style={Dividers.d10} />;
  }

  return null;
};

export default React.memo(DialogDivider);